<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Offers</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Offers"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              light
              :to="{ name: 'module-melrose-properties-offers' }"
            >
              <v-icon small left>mdi-arrow-left</v-icon> Back to Offers
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="offers"
        class="mt-4"
        show-expand
        no-data-text="No Offers found"
      >
        <template v-slot:item.property="{ item }">
          <router-link
            :to="{
              name: 'module-melrose-properties-individual',
              params: { propertyId: item.property.id },
            }"
            v-html="item.property.address.short"
          ></router-link>
        </template>
        <template v-slot:item.customer="{ item }">
          {{ item.enquiry.customer.full_name }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="yellow lighten-4 yellow--text text--darken-3"
                v-on="on"
                @click="openRestore(item)"
              >
                <v-icon small>mdi-restore</v-icon>
              </v-btn>
            </template>
            <span>Restore</span>
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pt-4 pb-4">
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Notes</th>
                  <td v-html="item.notes" class="pt-4 pb-4"></td>
                </tr>
                <tr>
                  <th>Solicitor</th>
                  <td class="pt-4 pb-4">
                    <div>{{ item.solicitor.name }}</div>
                    <div>
                      <strong>Phone:</strong> {{ item.solicitor.phone }}
                    </div>
                    <div>
                      <strong>Email:</strong> {{ item.solicitor.email }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
      <v-dialog v-model="restoreDialog.open" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">Restore Offer</v-card-title>
          <v-card-text>Are you sure you want to restore this?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetRestore"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="restoreDialog.loading"
              @click="saveRestore"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      restoreDialog: {
        open: false,
        loading: false,
        note_of_interest: {},
      },
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          exact: true,
          to: { name: "module-melrose-properties-all" },
        },
        {
          text: "Offers",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Date", value: "formatted_dates.date" },
        { text: "Property", value: "property" },
        { text: "Customer", value: "customer" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },

  computed: {
    offers() {
      let offers =
        this.$store.getters["melrose/propertiesStore/offers_archive"];

      if (this.searchTerm !== "") {
        offers = offers.filter((o) => {
          const property = o.address.short.toLowerCase();
          const customer = o.customer.full_name.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return property.includes(searchTerm) || customer.includes(searchTerm);
        });
      }

      return offers;
    },
  },

  methods: {
    openRestore(offer) {
      this.restoreDialog.offer = offer;
      this.restoreDialog.open = true;
    },

    resetRestore() {
      this.restoreDialog.loading = false;
      this.restoreDialog.open = false;
      this.restoreDialog.offer = {};
    },

    saveRestore() {
      const appId = this.$route.params.id;
      this.restoreDialog.loading = true;

      this.$store
        .dispatch("melrose/propertiesStore/restoreOffer", {
          appId,
          offerId: this.restoreDialog.offer.id,
        })
        .then(() => this.resetRestore())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
